<template>
  <tr :class="{ tr_select: isSelected }" @click="onClickSelect">
    <td class="td_ellip align_left" :data-ellip="rowData.personName">
      {{ rowData.personName }}
    </td>
    <td class="align_left">
      {{ rowData.accountId }}
    </td>

    <td class="td_ellip align_left" :data-ellip="`${rowData.companyName}/${rowData.deptName}`">
      {{ `${rowData.companyName}/${rowData.deptName}` }}
    </td>
  </tr>
</template>

<script>
export default {
  name: "DirectorLdapPopupLine",
  props: {
    rowData: Object,
    isSelected: Boolean,
  },
  methods: {
    onClickSelect() {
      this.$emit("onClickSelectItem", this.rowData);
    },
  },
};
</script>
