<template>
  <FilterBarBase btnSize="medium" @onClickSearch="onClickSearch" @onClickReset="onClickReset">
    <dl>
      <dt>이름/아이디 검색</dt>
      <dd>
        <Input
          class="w396"
          :value.sync="inputCurrentText"
          :isFocusOnMount="true"
          @onEnterKey="onClickSearch"
        />
      </dd>
    </dl>
  </FilterBarBase>
</template>

<script>
import FilterBarBase from "@/components/shared/filterBar/FilterBarBase";
import Input from "@/components/common/input/Input";

import FilterBarMixin from "@/mixins/FilterBarMixin";

export default {
  name: "DirectorLdapPopupFilterBar",
  components: {
    FilterBarBase,
    Input,
  },
  mixins: [FilterBarMixin],
  data() {
    return {
      inputData: {
        placeholder: null,
      },
      inputCurrentText: "",
    };
  },
  methods: {
    onClickSearch(e) {
      // if (!this.inputCurrentText.trim()) {
      //   this.alert('내용을 입력해주세요.');
      //   return;
      // }

      const ret = {
        text: this.inputCurrentText,
      };
      this.$emit("onClickSearch", ret);
    },
    onClickReset() {
      this.inputCurrentText = "";
    },
    alert(text) {
      this.$emit("alert", text);
    },
  },
};
</script>
