<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">담당자 상세 정보</h2>
      </div>
      <div class="body_section">
        <TableViewWithTitle>
          <template v-slot:headRight>
            <button type="button" class="btn_secondary btn_medium" @click="onClickPopLdap">
              조직도 검색
            </button>
          </template>
          <template v-slot:body>
            <tr>
              <th>ID<span class="ico_purchase ico_star">필수항목</span></th>
              <td colspan="3">
                <div class="group_form">
                  <Input class="w295" :isDisabled="true" :value.sync="itemId" />
                  <!-- :maxLength="10" /> -->
                  <button
                    type="button"
                    class="btn_fourthly btn_small"
                    :disabled="!itemId"
                    @click="onClickOverlap"
                  >
                    중복체크
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <th>권한<span class="ico_purchase ico_star">필수항목</span></th>
              <td>
                <div class="group_form">
                  <Radio
                    :name="authorityState.name"
                    :dataList="authorityState.dataList"
                    :selectedId.sync="authorityState.currentStatus"
                  />
                </div>
              </td>
              <th>계정상태<span class="ico_purchase ico_star">필수항목</span></th>
              <td>
                <div class="group_form">
                  <Radio
                    :name="acountState.name"
                    :dataList="acountState.dataList"
                    :selectedId.sync="acountState.currentStatus"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>이메일 수신여부<span class="ico_purchase ico_star">필수항목</span></th>
              <td>
                <div class="group_form">
                  <Radio
                    :name="emailState.name"
                    :dataList="emailState.dataList"
                    :selectedId.sync="emailState.currentStatus"
                  />
                </div>
              </td>
              <th>알림 수신여부<span class="ico_purchase ico_star">필수항목</span></th>
              <td>
                <div class="group_form">
                  <Radio
                    :name="alarmState.name"
                    :dataList="alarmState.dataList"
                    :selectedId.sync="alarmState.currentStatus"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>담당자명<span class="ico_purchase ico_star">필수항목</span></th>
              <td>
                <Input :value.sync="name" />
              </td>
              <th>부서명&#47;직급<span class="ico_purchase ico_star">필수항목</span></th>
              <td>
                <Input :value.sync="jobgrade" />
              </td>
            </tr>
            <tr>
              <th>담당 업무<span class="ico_purchase ico_star">필수항목</span></th>
              <td>
                <Input :value.sync="job" />
              </td>
              <th>이메일<span class="ico_purchase ico_star">필수항목</span></th>
              <td>
                <InputEmail :emailName.sync="email.name" :emailDomain.sync="email.domain" />
              </td>
            </tr>
            <tr>
              <th>직통번호<span class="ico_purchase ico_star">필수항목</span></th>
              <td>
                <InputPhone
                  :useMobile="true"
                  :useArea="true"
                  :phone0.sync="tel.phone0"
                  :phone1.sync="tel.phone1"
                  :phone2.sync="tel.phone2"
                />
              </td>
              <th>핸드폰번호</th>
              <td>
                <InputPhone
                  :useMobile="true"
                  :useArea="false"
                  :phone0.sync="mobile.phone0"
                  :phone1.sync="mobile.phone1"
                  :phone2.sync="mobile.phone2"
                />
              </td>
            </tr>
            <tr>
              <th>계정 등록일</th>
              <td>{{ regDate }}</td>
              <th>최근 접속일</th>
              <td>{{ lastDate }}</td>
            </tr>
          </template>
        </TableViewWithTitle>
      </div>
    </div>
    <Sticky>
      <div class="area_left">
        <button type="button" class="btn_fourthly btn_large" @click="onClickGoToList">목록</button>
      </div>
      <div class="area_right">
        <!-- <button type="button" class="btn_secondary btn_large"
          v-if="isModifyMode">삭제</button> -->
        <button type="submit" class="btn_primary btn_large" @click="onClickSave">저장</button>
      </div>
    </Sticky>
    <template v-slot:popup>
      <DirectorLdapPopup
        v-if="isPopLdap"
        @onClickSelect="onClickSelect"
        @onClickClose="onClickClosePopLdap"
        @alert="alert"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Input from "@/components/common/input/Input";
import Radio from "@/components/common/radio/Radio";
import InputEmail from "@/components/common/input/InputEmail";
import InputPhone from "@/components/common/input/InputPhone";

import Sticky from "@/components/layout/content/Sticky.vue";
import DirectorLdapPopup from "@/components/admin/system/director/popup/DirectorLdapPopup";

import PageMixin from "@/mixins/PageMixin";
import ApiService from "@/services/ApiService";
import { setPhone } from "@/utils/phoneUtils";
import { setEmail } from "@/utils/emailUtils";
import { getDateString } from "@/utils/dateUtils";
import DirectorWriteMixin from "./DirectorWriteMixin";

export default {
  name: "DirectorWrite",
  components: {
    PageWithLayout,
    TableViewWithTitle,
    Input,
    Radio,
    InputEmail,
    InputPhone,
    Sticky,
    DirectorLdapPopup,
  },
  mixins: [PageMixin, DirectorWriteMixin],
  data() {
    return {
      isCheckedOverlap: false,
      isPopLdap: false,
    };
  },
  methods: {
    onClickOverlap() {
      const itemId = this.itemId;
      if (itemId.trim() === "") return;

      // if( itemId.length > 10 ) {
      //   this.alert( '아이디는 최대 10자까지 허용됩니다.' );
      //   return;
      // }

      this.checkIdOverlap(this.itemId);
    },
    async checkIdOverlap(itemId) {
      const result = await ApiService.shared.getData(
        `${this.$apiPath.MANAGER_EXIST}?loginId=${itemId}`,
      );

      if (result.code === "200") {
        this.isCheckedOverlap = false;

        this.alert("이미 존재하는 계정입니다.");
        return;
      }

      this.isCheckedOverlap = true;

      this.alert("사용 가능한 id입니다.");
    },
    onClickSave() {
      const alertMess = this.getAlertMessage();

      if (alertMess) {
        this.alert(alertMess);
        return;
      }

      const isModifyMode = false;
      this.save(isModifyMode, this.$apiPath.MANAGER);
    },
    getAlertMessage() {
      if (!this.isCheckedOverlap) return "ID 중복 체크를 해주세요.";

      const result = this.getAlertMessageCommon();

      return result;
    },
    onClickSelect(rowData) {
      const { accountId, personName, deptName, emailId, tel, mobilePhone } = rowData;
      this.isPopLdap = false;
      this.itemId = accountId;
      this.name = personName;
      this.jobgrade = deptName;
      if (emailId) {
        const emailArray = emailId.split("@");
        this.email.name = emailArray[0];
        this.email.domain = emailArray[1];
      }
      if (tel) {
        const telArray = tel.split("-");
        this.tel.phone0 = telArray[0];
        this.tel.phone1 = telArray[1];
        this.tel.phone2 = telArray[2];
      }
      if (mobilePhone) {
        const mobilePhoneArray = mobilePhone.split("-");
        this.mobile.phone0 = mobilePhoneArray[0];
        this.mobile.phone1 = mobilePhoneArray[1];
        this.mobile.phone2 = mobilePhoneArray[2];
      }
    },
    onClickPopLdap() {
      this.isPopLdap = true;
    },
    onClickClosePopLdap() {
      this.isPopLdap = false;
    },
  },
};
</script>
